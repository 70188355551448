<template>
  <div class="modal is-active" ref="modal" tabindex="0" @keydown.esc.prevent="cancel">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-content">
      <div class="modal-card">
         <header class="modal-card-head">
           <p class="modal-card-title">Ben je zeker ?</p>
           <button class="delete" @click="cancel" aria-label="close"></button>
         </header>
         <section class="modal-card-body">
           <p class="has-text-weight-bold mb-2">
             Ben je zeker ?
           </p>
         </section>
         <footer class="modal-card-foot">
           <BulmaButton @click="confirm" :label="label" :type="type" :icon="icon" />
           <BulmaButton @click="cancel" label="Annuleren" type="is-danger" icon="cancel" />
         </footer>
       </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import BulmaButton from './BulmaButton.vue'
  export default{
    name:"BulmaConfirm",
    props:{
      type:{type:String,default:'is-success'},
      icon:{type:String,default:'arrow-right'},
      label:{type:String,default:'Bevestigen'}
    },
    components:{BulmaButton},
    data(){
      return  {
      }
    },methods:{
      confirm(event){
        this.$emit('confirm',event)
      },
      cancel(event){
        this.$emit('cancel',event)
      }
    },
    mounted(){
      this.$refs.modal.focus()
    }
  }
</script>
