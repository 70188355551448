<template>
  <div class="modal is-active" ref="modal" tabindex="0" @keydown.esc="cancel">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-content">
        <slot></slot>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default{
    name:"BulmaModal",
    props:{
    },
    data(){
      return  {
      }
    },methods:{
      cancel(event){
        this.$emit('cancel',event)
      }
    },
    mounted(){
      this.$refs.modal.focus()
    }
  }
</script>
