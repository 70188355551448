<template>
  <div class="modal is-active" ref="modal" tabindex="0" @keydown.esc="cancel">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-content">
      <div class="modal-card">
         <header class="modal-card-head">
           <p class="modal-card-title">{{ title }}</p>
           <button class="delete" @click="cancel" aria-label="close"></button>
         </header>
         <section class="modal-card-body">
           <p class="has-text-weight-bold mb-2">
             {{ label }}
           </p>
           <div class="control has-icons-left" :class="{'has-icons-right':icon2}">
             <input
               class="input"
               @keyup.enter="$emit('enterClicked')"
               :readonly="readonly"
               v-focus="focus"
               :type="type"
               tabindex="0"
               :id="_uid"
               :value="value"
               :class="{'is-danger':hasError||(required && ! value)||(required&&value&&value.length==0)}"
               @input="$emit('input', $event.target.value)"
               @blur="$emit('blur', $event.target.value)"
               :placeholder="placeholder"
              />
             <span class="icon is-left">
               <font-awesome-icon :icon="icon" />
             </span>
             <span class="icon is-right has-text-danger" v-if="icon2">
               <font-awesome-icon :icon="icon2" />
             </span>
           </div>
         </section>
         <footer class="modal-card-foot">
           <BulmaButton @click="ok" label="Ok" type="is-success" icon="check" />
           <BulmaButton @click="cancel" label="Annuleren" type="is-danger" icon="cancel" />
         </footer>
       </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import BulmaButton from './BulmaButton.vue'
  export default{
    name:"BulmaInputModal",
    props:{
      icon:{type:String,default:'arrow-right'},
      label:{type:String,default:'Input :'},
      focus:{type:String,default:""},
      value:{type:[String,Number],default:""},
      type:{type:String,default:"text"},
      readonly:{type:Boolean,default:false},
      required:{type:Boolean,default:false},
      placeholder:{type:String,default:""},
      icon2:{type:String,default:''},
      hasError:{type:Boolean,default:false},
      title:{type:String,default:""}
    },
    components:{BulmaButton},
    data(){
      return  {
      }
    },methods:{
      ok(event){
        this.$emit('ok',event)
      },
      cancel(event){
        this.$emit('cancel',event)
      }
    },
    mounted(){
      this.$refs.modal.focus()
    }
  }
</script>
